:root{
  --secondary : #FFC300;
  --secondary-light : #FFED00;
  --secondary-shade : #FFFCE5;
  --primary : #00A19A;
  --primary-light : #A1D4C8;
  --primary-dark : #006560;
  --black : #000;
  --blackAlpha : rgba(0,0,0,0.9);
  --white : #fff;
  --gray-light : #F2F2F2;
  --gray : #666666;
  --gray-dark : #333333;
  --gradient : linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333333 100%);
  --gradient-primary : linear-gradient(180deg, #006560 0%, rgba(0, 101, 96, 0) 100%);
  --swiper-theme-color : var(--text-brand-ultra-light);
  //BRAND
  --text-brand : #FFA553;
  --text-brand-light : #FEEBB1;
  --text-brand-dark : #FB5640;
  --text-brand-ultra-light: #FFF9E6;
  --text-base: #333;
  --text-complementary: #FEEBB1;
  --text-complementary-light: #FEEBB1;
  --text-complementary-dark: #FB5640;
  --text-complementary-ultra-dark: #FFF9E6;
  --text-complementary-ultra-light : #FFF9E6;
  --text-button-group-active : #FB5640;
  --surface-primary: #FFF;
  --surface-primary-ultra-light: #F2F2F2;
  --surface-secondary: #CCC;
  --surface-brand : #F24D28;
  --surface-brand-dark : #FFA553;
  --surface-brand-light : #FEEBB1;
  --surface-brand-ultra-light: #FFF9E6;
  --surface-complementary : #FB5640;
  --surface-complementary-light: #FEEBB1;
  --surface-complementary-dark : #FFF9E6;
}

body.spring{
  --text-brand : #FFA553;
  --text-brand-light : #FEEBB1;
  --text-brand-dark : #F24D28;
  --text-brand-ultra-light: #FFF9E6;
  --text-base: #333;
  --text-base-light: #666;
  --text-complementary: #006560;
  --text-complementary-dark: #00A19A;
  --text-complementary-ultra-dark: #004D49;
  --text-complementary-ultra-light : #ECF9F6;
  --text-button-group-active : #004D49;
  --surface-primary: #FFF;
  --surface-brand : #FFA553;
  --surface-brand-dark : #F24D28;
  --surface-brand-light : #FEEBB1;
  --surface-brand-ultra-light: #FFF9E6;
  --surface-complementary : #A1D4C8;
  --surface-complementary-light: #ECF9F6;
  --surface-complementary-dark : #00A19A;
}

body.winter{
  --text-brand : #FB5640;
  --text-brand-light : #FAB5AB;
  --text-brand-dark : #F24D28;
  --text-brand-ultra-light: #FEEAE7;
  --text-base: #333;
  --text-base-light: #666;
  --text-complementary: #223076;
  --text-complementary-dark: #FEEAE7;
  --text-complementary-ultra-dark: #FFF;
  --text-complementary-ultra-light : #FEEAE7;
  --text-button-group-active : #223076;
  --surface-primary: #FFF;
  --surface-brand : #FB5640;
  --surface-brand-dark : #223076;
  --surface-brand-light : #FAB5AB;
  --surface-brand-ultra-light: #FEEAE7;
  --surface-complementary : #223076;
  --surface-complementary-light: #FAB5AB;
  --surface-complementary-dark : #FAB5AB;
}

body.summer{
  --text-brand : #FFA553;
  --text-brand-light : #FEEBB1;
  --text-brand-dark : #FB5640;
  --text-brand-ultra-light: #FFF9E6;
  --text-base: #333;
  --text-base-light: #666;
  --text-complementary: #FEEBB1;
  --text-complementary-light: #FEEBB1;
  --text-complementary-dark: #FB5640;
  --text-complementary-ultra-dark: #FFF9E6;
  --text-complementary-ultra-light : #FFF9E6;
  --text-button-group-active : #FB5640;
  --surface-primary: #FFF;
  --surface-brand : #F24D28;
  --surface-brand-dark : #FFA553;
  --surface-brand-light : #FEEBB1;
  --surface-brand-ultra-light: #FFF9E6;
  --surface-complementary : #FB5640;
  --surface-complementary-light: #FEEBB1;
  --surface-complementary-dark : #FEEBB1;
}
body.autumn{
  --text-brand : #FFA553;
  --text-brand-light : #FEEBB1;
  --text-brand-dark : #FB5640;
  --text-brand-ultra-light: #FEEAE7;
  --text-base: #333;
  --text-base-light: #666;
  --text-complementary: #006560;
  --text-complementary-light: #ECF9F6;
  --text-complementary-dark: #004D49;
  --text-complementary-ultra-light : #ECF9F6;
  --text-complementary-ultra-dark : #ECF9F6;
  --text-button-group-active : #006560;
  --surface-primary: #FFF;
  --surface-brand : #FFA553;
  --surface-brand-dark : #F24D28;
  --surface-brand-light : #FEEBB1;
  --surface-brand-ultra-light: #FFF9E6;
  --surface-complementary : #006560;
  --surface-complementary-light: #A1D4C8;
  --surface-complementary-dark : #004D49;
}


