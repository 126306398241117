body, .gform-theme--framework .gfield{
  select, textarea, input[type='text'],input[type='number'],input[type='search'],input[type='date'],input[type='tel'],input[type='password'],input[type='email']{
    height: 2.375rem;
    color: var(--text-base-light);
    font-size: 1rem;
    box-shadow: none !important;
    border-radius: var(--spacing-spacing-2xl);
    background: var(--surface-brand-ultra-light);
    padding: 0.5rem 1rem;
    border: none;
    &[readonly] {
      &:focus {
        border: none;
        outline: none;
      }
    }
    &[disabled] {
      background: var(--bg1);

      &[readonly] {
        background: var(--bg1);
      }
    }
    &:not([readonly]):focus {
      border: 1px solid var(--active);
      outline: none;
    }
    &.xs {
      height: 2rem;
      font-size: 1rem;
      padding: 0.1rem 0.05rem;
    }
  }
  textarea{
    height: auto;
    border-radius: var(--spacing-spacing-m, 1rem);
  }
  .gfield_label.gform-field-label{
    color: var(--text-brand-dark);
  }
  .gfield select{
    line-height: 1.4em !important;
  }
  .gform-theme--framework .gfield:where(.gfield--type-fileupload,.gfield--input-type-fileupload) .gform_drop_area::before{
    color: var(--text-brand-dark) !important;
  }
}



.gform_button{
  display: inline-block;
}

