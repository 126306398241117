.page-sections{
  text-images-section{
    .content{
      strong{
        font-weight: 700;
      }
    }
  }
}

.section-impair{
  position: relative;
  overflow: hidden;
  &:before{
    content: "\EA41";
    font-size:32rem;
    height:32rem;
    display: flex;
    align-items: center;
    position: absolute;
    right: -1.875rem;
    top: -3.5rem;
    opacity: 0.2;
    font-family: "var-icons";
    font-style: normal;
    font-weight: normal;
    color: var(--text-brand);
  }
}
