body,body .gform-theme.gform-theme--framework.gform_wrapper .gform_footer {
  .button,.btn-page,input[type=submit].button{
    display: inline-flex;
    border:0;
    padding: var(--spacing-spacing-s, 0.5rem) var(--spacing-spacing-m, 1rem);
    align-items: center;
    gap: 0.625rem;
    font-weight: 800;
    color: var(--text-complementary-ultra-dark) !important;
    background: var(--surface-complementary);
    box-shadow: -4px 4px 0px 0px var(--surface-complementary-dark);
    transition: background 0.3s, box-shadow 0.3s;
    margin-left:4px;
    line-height: 1.4em;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0;
    &:hover{
      background: var(--text-brand);
      box-shadow: -8px 8px 0px 0px var(--surface-complementary-dark);
    }
    [class*=lvi-]{
      font-size: 1.375rem;
      display: inline-flex;
    }
    &.active{
      background: var(--text-brand);
      box-shadow: -4px 4px 0px 0px var(--text-brand-dark);
      &:hover{
        box-shadow: -4px 4px 0px 0px var(--text-brand-dark);
      }
    }
  }
  .gform-theme.gform-theme--framework.gform_wrapper .gform_footer {
    .button, input[type=submit].button {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .button-outline-white{
    color: var(--white) !important;
    background: none;
    box-shadow: none;
    border: 1px solid var(--white);
    transition: background 0.3s, box-shadow 0.3s !important;
    cursor: pointer;
    &:hover{
      box-shadow: -8px 8px 0px 0px var(--white);
    }
  }

  .button-outline-brand{
    color: var(--text-brand) !important;
    background: var(--white);
    box-shadow: none;
    border: 1px solid var(--text-brand);
    transition: box-shadow 0.3s;
    cursor: pointer;
    &:hover{
      background: var(--white) !important;
      box-shadow: -8px 8px 0px 0px var(--text-brand);
    }
    &.active{
      color: var(--text-button-group-active) !important;
    }
  }

  .buttons-group{
    display: flex;
    padding: var(--spacing-spacing-xs);
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    background: var(--surface-complementary);
    box-shadow: -4px 4px 0px 0px var(--surface-complementary-dark);
    .button-item{
      display: flex;
      padding: var(--spacing-spacing-xs) var(--spacing-spacing-m);
      align-items: center;
      cursor:pointer;
      color: var(--text-complementary-ultra-dark);
      font-size: 1rem;
      font-weight: 800;
      background: none;
      text-transform: uppercase;
      border: 1px solid transparent;
      transition: all 0.3s;
      &:hover{
        border: 1px solid var(--surface-primary);
      }
      &.active{
        background: var(--surface-primary);
        color: var(--text-button-group-active);
      }
    }
  }
  .gform-theme-button,.gform_button,.gform-theme-button--size-sm{
    background: var(--surface-complementary) !important;
    color: var(--text-complementary-ultra-dark) !important;
    border:none !important;
    border-radius: 0 !important;
    &:hover{
      background: var(--text-brand);
      box-shadow: -8px 8px 0px 0px var(--surface-complementary-dark);
    }
  }
}
