#ContactInfos{
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    margin-bottom: var(--spacing-3);
    .contact-item {
      display: flex;
      align-items: center;
      gap: var(--spacing-2);
      margin-bottom: 10px;
      i {
        font-size: 1.6rem;
        min-width: 3.2rem;
        height:3.2rem;
        border: 1px solid var(--surface-brand);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--surface-brand);

      }
      span {
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }

  .follow-us {
    background-color:  var(--surface-brand-ultra-light);
    overflow: hidden;
    border: 10px solid var(--surface-brand);
    padding: var(--spacing-4);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    &:before,&:after{
      background: repeating-linear-gradient(var(--surface-brand), var(--surface-brand) 10px, transparent 10px, transparent 2rem);
      width:20px;
      display: block;
      content: "";
      height:7rem;
      position: absolute;
      top:50%;
      margin-top: -3.5rem;
    }
    &:before{
      left:0;
    }
    &:after{
      right:0;
    }
    p {
      font-size: 2.4rem;
      font-weight: 900;
      color:  var(--surface-brand);
    }
    .social-icons {
      display: flex;
      justify-content: center;
      i {
        font-size: 2.4rem;
        color: var(--surface-brand);
        margin: 0 10px;
        transition: color 0.3s;
        &:hover{
          color: var(--surface-brand-dark);
        }
      }
    }
  }
}
