@import "breakpoints";
body{
  font-family: var(--font);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6em;
  text-overflow: ellipsis;
  color: var(--text-base);
}

h1 {
  color: var(--text-brand);
  text-shadow: -3px 3px 0px var(--text-brand-light);
  font-family: var(--accent-font);
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3em;
  text-transform: uppercase;
  margin-bottom: var(--spacing-3);
}
h2,.h2 {
  color: var(--text-brand);
  text-shadow: -3px 3px 0px var(--text-brand-light);
  font-family: var(--accent-font);
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: var(--spacing-3);
  text-transform: uppercase;
}
.h3 {
  color: var(--text-brand);
  text-shadow: -3px 3px 0px var(--text-brand-light);
  font-family: var(--accent-font);
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: var(--spacing-3);
  text-transform: uppercase;
}

h3{
  color: var(--text-brand);
  font-family: var(--font);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: var(--spacing-2);
}

.h5{
  color: var(--text-brand, #FFA553);
  text-shadow: -3px 3px 0px var(--text-brand-light);
  font-family: var(--accent-font);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.intro,.introduction {
  font-size: 1.125rem;
}
p{
  margin-bottom: var(--spacing-2);
  &:empty{
    display: none;
  }
}
a{
  color: var(--text-brand);
  transition: all 0.3s;
  &:visited{
    color: var(--text-brand);
  }
  &:hover,&:focus{
    color: var(--text-brand-dark);
  }
  &[type=button],&.button{
    color: var(--text-brand-ultra-light);
    transition: all 0.3s;
    &:visited{
      color: var(--text-brand-ultra-light);
    }
    &:hover,&:focus{
      color: var(--text-brand-light);
    }
  }
}
blockquote{
  padding: var(--spacing-2);
  padding-left: var(--spacing-4);
  margin-bottom: var(--spacing-2);
  position: relative;
  &:after{
    content: "\EA46";
    position: absolute;
    left:-1.6rem;
    font-size: 2rem;
    min-width: 3.2rem;
    margin-top: -1.6rem;
    top: 50%;
    height:3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    color: var(--text-brand);
    font-family: "var-icons";
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  >*{
    position: relative;
    z-index: 1;
  }
  &:before{
    content: "";
    border:2px solid var(--text-brand);
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    clip-path: polygon(0 0, 0 calc(50% - 1.6rem), 2px calc(50% - 1.6rem),2px calc(50% + 1.6rem), 0 calc(50% + 1.6rem), 0 100%, 100% 100%, 100% 0);
  }

  p:last-child{
    margin-bottom: 0;
  }
}

.reverse{
  color: var(--white);
  h1 {
    color: var(--white);
    text-shadow: -3px 3px 0px var(--surface-brand);
  }
  h1,h2,.h1,.h2,.h3,.h5 {
    color: var(--white);
    text-shadow: -3px 3px 0px var(--surface-brand);
  }
  .h5{
    text-shadow: -2px 1px 0px var(--surface-brand);
  }
  h3 {
    color: var(--white);
  }
  .intro {

  }
  a:not([type=button]),a:not(.button){
    color: var(--white);
    transition: color 0.3s;
    &:visited{
      color: var(--white);
    }
    &:hover,&:focus{
      color: var(--text-brand-light);
    }
  }
}

.text-image-container .content,.wpb_content_element{
  strong{
    font-weight: 700;
  }
  a{
    overflow: hidden;
    img{
      transition: transform 0.3s;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
  }
  ul{
    margin-bottom: var(--spacing-2);
    li{
      padding-left: 1rem;
      position: relative;
      &:before{
        position: absolute;
        top:0.35rem;
        left:0;
        width:0.75rem;
        height:0.75rem;
        content: " ";
        background: var(--text-brand);
        margin-right:0.5rem;
        min-width: 0.75rem;
      }
      +li{
        margin-top:var(--spacing-1);
      }
    }

  }
}

@media (max-width: $desktop-breakpoint){
  h1, .h1{
    font-size: 1.8rem !important;
  }
  h2, .h2{
    font-size: 1.7rem !important;
  }
  h3, .h3{
    font-size: 1.5rem !important;
  }
}

