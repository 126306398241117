@import "scss/colors";
@import "scss/variables";
@import "scss/breakpoints";
@import "scss/spacings";
@import "scss/fonts";
@import "scss/bayadares";
@import "scss/helpers";
@import "scss/reset";
@import "aos/src/sass/aos";
@import "assets/fonticons/scss/var-icons";
@import "scss/ol";
@import "scss/texts";
@import "scss/animations";
@import "scss/placeholders";
@import "scss/scrollbars";
@import "scss/grids";
@import "scss/flex";
@import "scss/sizes";
@import "scss/gaps";
@import "scss/buttons";
@import "scss/tags";
@import "scss/home";
@import "scss/sections";
@import "scss/overflow";
@import "scss/instagram";
@import "scss/inputs";
@import "scss/modals";
@import 'swiper/swiper-bundle.min.css';
@import "scss/responsive";
@import "scss/results-section";
@import "scss/gravityforms";
@import "scss/wysiwyg";
@import "scss/tooltips";
@import "scss/search";
@import "scss/contact";
@import "scss/cookieconsent";
@import "scss/calendars";

*{
  box-sizing: border-box;
}
body{
  overflow-x: hidden;
  overflow-y: auto;
  &.unscrollable{
    overflow: hidden;
  }
}


