.grid{
  max-width: 100%;
  padding-left: var(--spacing-spacing-m);
  padding-right: var(--spacing-spacing-m);
  margin-left:auto;
  margin-right:auto;
  display: grid;
  position: relative;

  &.grid-section{
    width: 90rem;
    padding-left: var(--spacing-edges);
    padding-right: var(--spacing-edges);
  }
  &.grid-section-large{
    width: 103rem;
  }
}

.grid-reverse {
  grid-auto-flow: dense;
  direction: rtl;
  [class*='col-']{
   direction: ltr;
  }
}

.grid-cols-1	{grid-template-columns: repeat(1, minmax(0, 1fr));}
.grid-cols-2	{grid-template-columns: repeat(2, minmax(0, 1fr));}
.grid-cols-3	{grid-template-columns: repeat(3, minmax(0, 1fr));}
.grid-cols-4	{grid-template-columns: repeat(4, minmax(0, 1fr));}
.grid-cols-5	{grid-template-columns: repeat(5, minmax(0, 1fr));}
.grid-cols-6	{grid-template-columns: repeat(6, minmax(0, 1fr));}
.grid-cols-7	{grid-template-columns: repeat(7, minmax(0, 1fr));}
.grid-cols-8	{grid-template-columns: repeat(8, minmax(0, 1fr));}
.grid-cols-9	{grid-template-columns: repeat(9, minmax(0, 1fr));}
.grid-cols-10	{grid-template-columns: repeat(10, minmax(0, 1fr));}
.grid-cols-11	{grid-template-columns: repeat(11, minmax(0, 1fr));}
.grid-cols-12	{grid-template-columns: repeat(12, minmax(0, 1fr));}

@media (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint){
  .sm-grid-cols-1	{grid-template-columns: repeat(1, minmax(0, 1fr));}
  .sm-grid-cols-2	{grid-template-columns: repeat(2, minmax(0, 1fr));}
  .sm-grid-cols-3	{grid-template-columns: repeat(3, minmax(0, 1fr));}
  .sm-grid-cols-4	{grid-template-columns: repeat(4, minmax(0, 1fr));}
  .sm-grid-cols-5	{grid-template-columns: repeat(5, minmax(0, 1fr));}
  .sm-grid-cols-6	{grid-template-columns: repeat(6, minmax(0, 1fr));}
  .sm-grid-cols-7	{grid-template-columns: repeat(7, minmax(0, 1fr));}
  .sm-grid-cols-8	{grid-template-columns: repeat(8, minmax(0, 1fr));}
  .sm-grid-cols-9	{grid-template-columns: repeat(9, minmax(0, 1fr));}
  .sm-grid-cols-10	{grid-template-columns: repeat(10, minmax(0, 1fr));}
  .sm-grid-cols-11	{grid-template-columns: repeat(11, minmax(0, 1fr));}
  .sm-grid-cols-12	{grid-template-columns: repeat(12, minmax(0, 1fr));}
}
@media (min-width: $tablet-breakpoint+1){
  .md-grid-cols-1	{grid-template-columns: repeat(1, minmax(0, 1fr));}
  .md-grid-cols-2	{grid-template-columns: repeat(2, minmax(0, 1fr));}
  .md-grid-cols-3	{grid-template-columns: repeat(3, minmax(0, 1fr));}
  .md-grid-cols-4	{grid-template-columns: repeat(4, minmax(0, 1fr));}
  .md-grid-cols-5	{grid-template-columns: repeat(5, minmax(0, 1fr));}
  .md-grid-cols-6	{grid-template-columns: repeat(6, minmax(0, 1fr));}
  .md-grid-cols-7	{grid-template-columns: repeat(7, minmax(0, 1fr));}
  .md-grid-cols-8	{grid-template-columns: repeat(8, minmax(0, 1fr));}
  .md-grid-cols-9	{grid-template-columns: repeat(9, minmax(0, 1fr));}
  .md-grid-cols-10	{grid-template-columns: repeat(10, minmax(0, 1fr));}
  .md-grid-cols-11	{grid-template-columns: repeat(11, minmax(0, 1fr));}
  .md-grid-cols-12	{grid-template-columns: repeat(12, minmax(0, 1fr));}
}

.col-auto	      {grid-column: auto;}
.col-span-1	    {grid-column: span 1 / span 1;}
.col-span-2	    {grid-column: span 2 / span 2;}
.col-span-3	    {grid-column: span 3 / span 3;}
.col-span-4	    {grid-column: span 4 / span 4;}
.col-span-5	    {grid-column: span 5 / span 5;}
.col-span-6	    {grid-column: span 6 / span 6;}
.col-span-7	    {grid-column: span 7 / span 7;}
.col-span-8	    {grid-column: span 8 / span 8;}
.col-span-9	    {grid-column: span 9 / span 9;}
.col-span-10	  {grid-column: span 10 / span 10;}
.col-span-11	  {grid-column: span 11 / span 11;}
.col-span-12	  {grid-column: span 12 / span 12;}
.col-span-full	{grid-column: 1 / -1;}
.col-start-1	  {grid-column-start: 1;}
.col-start-2	  {grid-column-start: 2;}
.col-start-3	  {grid-column-start: 3;}
.col-start-4	  {grid-column-start: 4;}
.col-start-5	  {grid-column-start: 5;}
.col-start-6	  {grid-column-start: 6;}
.col-start-7	  {grid-column-start: 7;}
.col-start-8	  {grid-column-start: 8;}
.col-start-9	  {grid-column-start: 9;}
.col-start-10	  {grid-column-start: 10;}
.col-start-11	  {grid-column-start: 11;}
.col-start-12	  {grid-column-start: 12;}
.col-start-13	  {grid-column-start: 13;}
.col-start-auto	{grid-column-start: auto;}
.col-end-1	    {grid-column-end: 1;}
.col-end-2	    {grid-column-end: 2;}
.col-end-3	    {grid-column-end: 3;}
.col-end-4	    {grid-column-end: 4;}
.col-end-5	    {grid-column-end: 5;}
.col-end-6	    {grid-column-end: 6;}
.col-end-7	    {grid-column-end: 7;}
.col-end-8	    {grid-column-end: 8;}
.col-end-9	    {grid-column-end: 9;}
.col-end-10	    {grid-column-end: 10;}
.col-end-11	    {grid-column-end: 11;}
.col-end-12	    {grid-column-end: 12;}
.col-end-13	    {grid-column-end: 13;}
.col-end-auto	  {grid-column-end: auto;}

@media (min-width: $mobile-breakpoint) and (max-width: $tablet-breakpoint){
  .sm-col-auto	      {grid-column: auto;}
  .sm-col-span-1	    {grid-column: span 1 / span 1;}
  .sm-col-span-2	    {grid-column: span 2 / span 2;}
  .sm-col-span-3	    {grid-column: span 3 / span 3;}
  .sm-col-span-4	    {grid-column: span 4 / span 4;}
  .sm-col-span-5	    {grid-column: span 5 / span 5;}
  .sm-col-span-6	    {grid-column: span 6 / span 6;}
  .sm-col-span-7	    {grid-column: span 7 / span 7;}
  .sm-col-span-8	    {grid-column: span 8 / span 8;}
  .sm-col-span-9	    {grid-column: span 9 / span 9;}
  .sm-col-span-10	  {grid-column: span 10 / span 10;}
  .sm-col-span-11	  {grid-column: span 11 / span 11;}
  .sm-col-span-12	  {grid-column: span 12 / span 12;}
  .sm-col-span-full	{grid-column: 1 / -1;}
  .sm-col-start-1	  {grid-column-start: 1;}
  .sm-col-start-2	  {grid-column-start: 2;}
  .sm-col-start-3	  {grid-column-start: 3;}
  .sm-col-start-4	  {grid-column-start: 4;}
  .sm-col-start-5	  {grid-column-start: 5;}
  .sm-col-start-6	  {grid-column-start: 6;}
  .sm-col-start-7	  {grid-column-start: 7;}
  .sm-col-start-8	  {grid-column-start: 8;}
  .sm-col-start-9	  {grid-column-start: 9;}
  .sm-col-start-10	  {grid-column-start: 10;}
  .sm-col-start-11	  {grid-column-start: 11;}
  .sm-col-start-12	  {grid-column-start: 12;}
  .sm-col-start-13	  {grid-column-start: 13;}
  .sm-col-start-auto	{grid-column-start: auto;}
  .sm-col-end-1	    {grid-column-end: 1;}
  .sm-col-end-2	    {grid-column-end: 2;}
  .sm-col-end-3	    {grid-column-end: 3;}
  .sm-col-end-4	    {grid-column-end: 4;}
  .sm-col-end-5	    {grid-column-end: 5;}
  .sm-col-end-6	    {grid-column-end: 6;}
  .sm-col-end-7	    {grid-column-end: 7;}
  .sm-col-end-8	    {grid-column-end: 8;}
  .sm-col-end-9	    {grid-column-end: 9;}
  .sm-col-end-10	    {grid-column-end: 10;}
  .sm-col-end-11	    {grid-column-end: 11;}
  .sm-col-end-12	    {grid-column-end: 12;}
  .sm-col-end-13	    {grid-column-end: 13;}
  .sm-col-end-auto	  {grid-column-end: auto;}
}

@media (min-width: $tablet-breakpoint+1){
  .md-col-auto	      {grid-column: auto;}
  .md-col-span-1	    {grid-column: span 1 / span 1;}
  .md-col-span-2	    {grid-column: span 2 / span 2;}
  .md-col-span-3	    {grid-column: span 3 / span 3;}
  .md-col-span-4	    {grid-column: span 4 / span 4;}
  .md-col-span-5	    {grid-column: span 5 / span 5;}
  .md-col-span-6	    {grid-column: span 6 / span 6;}
  .md-col-span-7	    {grid-column: span 7 / span 7;}
  .md-col-span-8	    {grid-column: span 8 / span 8;}
  .md-col-span-9	    {grid-column: span 9 / span 9;}
  .md-col-span-10	  {grid-column: span 10 / span 10;}
  .md-col-span-11	  {grid-column: span 11 / span 11;}
  .md-col-span-12	  {grid-column: span 12 / span 12;}
  .md-col-span-full	{grid-column: 1 / -1;}
  .md-col-start-1	  {grid-column-start: 1;}
  .md-col-start-2	  {grid-column-start: 2;}
  .md-col-start-3	  {grid-column-start: 3;}
  .md-col-start-4	  {grid-column-start: 4;}
  .md-col-start-5	  {grid-column-start: 5;}
  .md-col-start-6	  {grid-column-start: 6;}
  .md-col-start-7	  {grid-column-start: 7;}
  .md-col-start-8	  {grid-column-start: 8;}
  .md-col-start-9	  {grid-column-start: 9;}
  .md-col-start-10	  {grid-column-start: 10;}
  .md-col-start-11	  {grid-column-start: 11;}
  .md-col-start-12	  {grid-column-start: 12;}
  .md-col-start-13	  {grid-column-start: 13;}
  .md-col-start-auto	{grid-column-start: auto;}
  .md-col-end-1	    {grid-column-end: 1;}
  .md-col-end-2	    {grid-column-end: 2;}
  .md-col-end-3	    {grid-column-end: 3;}
  .md-col-end-4	    {grid-column-end: 4;}
  .md-col-end-5	    {grid-column-end: 5;}
  .md-col-end-6	    {grid-column-end: 6;}
  .md-col-end-7	    {grid-column-end: 7;}
  .md-col-end-8	    {grid-column-end: 8;}
  .md-col-end-9	    {grid-column-end: 9;}
  .md-col-end-10	    {grid-column-end: 10;}
  .md-col-end-11	    {grid-column-end: 11;}
  .md-col-end-12	    {grid-column-end: 12;}
  .md-col-end-13	    {grid-column-end: 13;}
  .md-col-end-auto	  {grid-column-end: auto;}
}
