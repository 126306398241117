@mixin bayadares {
  content:"";
  height: 7rem;
  width:9999px;
  background: repeating-linear-gradient(var(--surface-brand), var(--surface-brand) var(--spacing-2), transparent var(--spacing-2), transparent 2rem);
}

.bayadares-before{
  &:before{
    @include bayadares;
  }
}

.bayadares-after{
  &:after{
    @include bayadares;
  }
}
