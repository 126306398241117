.vv-overlay{
  width:100%;
  height:100%;
  position: absolute;
  display: flex;
  left:0;
  top:0;
}

.before-overlay{
  &:before{
    width:100%;
    height:100%;
    position: absolute;
    content:" ";
    display: flex;
    left:0;
    top:0;
  }
}

.after-overlay{
  &:after{
    width:100%;
    height:100%;
    display: flex;
    position: absolute;
    content:" ";
    left:0;
    top:0;
  }
}
