.flex{
  display:flex;
}
.flex-1{
  flex:1;
}
.items-center{
  align-items:center;
}
.items-start{
  align-items:flex-start;
}
.items-end{
  align-items:flex-end;
}
.space-between{
  justify-content: space-between;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-column{
  flex-direction: column;
}
