$lvipath : 'assets/fonticons/fonts/';
@font-face {
    font-family: "var-icons";
    src: url( $lvipath + 'var-icons.eot');
    src: url( $lvipath + 'var-icons.eot?#iefix') format('eot'),
      url( $lvipath + 'var-icons.woff2') format('woff2'),
      url( $lvipath + 'var-icons.woff') format('woff'),
      url( $lvipath + 'var-icons.ttf') format('truetype'),
      url( $lvipath + 'var-icons.svg#var-icons') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  [class*=lvi-]{
    line-height: 100%;
  }
  [class*=lvi-]:before {
    font-family: "var-icons";
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-flex;
  }

.lvi-icon-animal-outline:before { content: "\EA01" }
.lvi-icon-calendar-outline:before { content: "\EA02" }
.lvi-icon-car-outline:before { content: "\EA03" }
.lvi-icon-cheque-vacances:before { content: "\EA04" }
.lvi-icon-cheque:before { content: "\EA05" }
.lvi-icon-chevron-down:before { content: "\EA06" }
.lvi-icon-chevron-left:before { content: "\EA07" }
.lvi-icon-clock:before { content: "\EA08" }
.lvi-icon-credit-card:before { content: "\EA09" }
.lvi-icon-cross:before { content: "\EA0A" }
.lvi-icon-double-chevron:before { content: "\EA0B" }
.lvi-icon-ear:before { content: "\EA0C" }
.lvi-icon-enveloppe:before { content: "\EA0D" }
.lvi-icon-especes:before { content: "\EA0E" }
.lvi-icon-eye:before { content: "\EA0F" }
.lvi-icon-facebook-outline:before { content: "\EA10" }
.lvi-icon-filter-outline:before { content: "\EA11" }
.lvi-icon-handicap-mental:before { content: "\EA12" }
.lvi-icon-heart-fill:before { content: "\EA13" }
.lvi-icon-heart-outline:before { content: "\EA14" }
.lvi-icon-house-outline:before { content: "\EA15" }
.lvi-icon-info:before { content: "\EA16" }
.lvi-icon-instagram-outline:before { content: "\EA17" }
.lvi-icon-leaf:before { content: "\EA18" }
.lvi-icon-linkedin:before { content: "\EA19" }
.lvi-icon-map:before { content: "\EA1A" }
.lvi-icon-menu:before { content: "\EA1B" }
.lvi-icon-phone-outline:before { content: "\EA1C" }
.lvi-icon-pin-flat:before { content: "\EA1D" }
.lvi-icon-pin-outline:before { content: "\EA1E" }
.lvi-icon-pinterest:before { content: "\EA1F" }
.lvi-icon-plus:before { content: "\EA20" }
.lvi-icon-pmr-outline:before { content: "\EA21" }
.lvi-icon-pointer:before { content: "\EA22" }
.lvi-icon-rain-outline:before { content: "\EA23" }
.lvi-icon-road:before { content: "\EA24" }
.lvi-icon-search:before { content: "\EA25" }
.lvi-icon-share-outline:before { content: "\EA26" }
.lvi-icon-star-flat:before { content: "\EA27" }
.lvi-icon-star-outline:before { content: "\EA28" }
.lvi-icon-sun:before { content: "\EA29" }
.lvi-icon-threads:before { content: "\EA2A" }
.lvi-icon-ticket:before { content: "\EA2B" }
.lvi-icon-tiktok-solid:before { content: "\EA2C" }
.lvi-icon-tiktok:before { content: "\EA2D" }
.lvi-icon-train:before { content: "\EA2E" }
.lvi-icon-trash:before { content: "\EA2F" }
.lvi-icon-warning:before { content: "\EA30" }
.lvi-icon-website-outline:before { content: "\EA31" }
.lvi-icon-wifi:before { content: "\EA32" }
.lvi-icon-x:before { content: "\EA33" }
.lvi-icon-youtube-1:before { content: "\EA34" }
.lvi-icon-youtube-solid:before { content: "\EA35" }
.lvi-icon-youtube:before { content: "\EA36" }
.lvi-thel-parking:before { content: "\EA37" }
.lvi-thel-animals:before { content: "\EA38" }
.lvi-thel-date:before { content: "\EA39" }
.lvi-thel-distance:before { content: "\EA3A" }
.lvi-thel-download-pdf:before { content: "\EA3B" }
.lvi-thel-duree:before { content: "\EA3C" }
.lvi-thel-email:before { content: "\EA3D" }
.lvi-thel-etapes:before { content: "\EA3E" }
.lvi-thel-facebook:before { content: "\EA3F" }
.lvi-thel-filigrane-background:before { content: "\EA41" }
.lvi-thel-handicap-auditif:before { content: "\EA42" }
.lvi-thel-handicap-mental:before { content: "\EA43" }
.lvi-thel-handicap-moteur:before { content: "\EA44" }
.lvi-thel-handicap-visuel:before { content: "\EA45" }
.lvi-thel-info:before { content: "\EA46" }
.lvi-thel-instagram:before { content: "\EA47" }
.lvi-thel-lieu:before { content: "\EA48" }
.lvi-thel-linkedin:before { content: "\EA49" }
.lvi-thel-meteo-neige:before { content: "\EA4A" }
.lvi-thel-meteo-pluie:before { content: "\EA4B" }
.lvi-thel-meteo-soleil:before { content: "\EA4C" }
.lvi-thel-prix:before { content: "\EA4D" }
.lvi-thel-reservation:before { content: "\EA4E" }
.lvi-thel-telephone:before { content: "\EA4F" }
.lvi-thel-threads:before { content: "\EA50" }
.lvi-thel-tiktok:before { content: "\EA51" }
.lvi-thel-tourisme-durable:before { content: "\EA52" }
.lvi-thel-train:before { content: "\EA53" }
.lvi-thel-trash:before { content: "\EA54" }
.lvi-thel-twitter-x:before { content: "\EA55" }
.lvi-thel-website:before { content: "\EA56" }
.lvi-thel-wifi:before { content: "\EA57" }
.lvi-thel-youtube:before { content: "\EA58" }
.lvi-thel-coeur-plein:before { content: "\EA59" }
.lvi-thel-coeur-vide:before { content: "\EA60" }
.lvi-thel-loupe:before { content: "\EA61" }
.lvi-thel-temperature-chaud:before { content: "\EA62" }
.lvi-thel-temperature-froid:before { content: "\EA63" }
.lvi-thel-accueil:before { content: "\EA64" }
.lvi-thel-dimanche:before { content: "\EA65" }
.lvi-thel-soiree:before { content: "\EA66" }
.lvi-thel-vent:before { content: "\EA67" }
.lvi-thel-webcam:before { content: "\EA68" }
.lvi-bike:before { content: "\EA69" }
.lvi-car:before { content: "\EA6A" }
.lvi-walk:before { content: "\EA6B" }
