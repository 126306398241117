$tooltipColor : rgba(0,0,0,0.7);
.tooltip{
  position: fixed;
  background:$tooltipColor;
  color:var(--white);
  z-index: 3;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  max-width: 30rem;
  font-size: 0.8rem;
  line-height: 1.05em;
  &:after{
    content:'';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;

    transform: rotate(0deg);

  }
  &.left{
   &:after{
     border-width: 5px 0 5px 7px;
     border-color: transparent transparent transparent $tooltipColor;
     left:100%;
     top:50%;
     margin-top:-5px;
   }
  }
  &.top{
    &:after{
      border-width: 7px 5px 0 5px;
      border-color: $tooltipColor transparent transparent transparent;
      left:50%;
      margin-left:-5px;
      bottom:-7px;
    }
  }
  &.right{
    &:after{
      border-width: 5px 7px 5px 0;
      border-color: transparent $tooltipColor transparent transparent;
      left:-7px;
      top:50%;
      margin-top:-5px;
    }
  }
  &.bottom{
    &:after{
      border-width: 0 5px 7px 5px;
      border-color: transparent transparent $tooltipColor transparent;
      left:50%;
      margin-left:-5px;
      top:-7px;
    }
  }
}
