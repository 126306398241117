.results-section {
  .result-items{
    padding-bottom:var(--spacing-4);
    .h3{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--text-brand);
      margin-bottom: var(--spacing-1);
    }
  }
  .results-navigation{
    .results-menu{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-1);
      padding-bottom: var(--spacing-1);
      li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        cursor:pointer;
        .title{
          color: var(--text-base);
          font-size: 1.25rem;
          font-weight: 500;
          transition: color 0.3s;
        }
        .count{
          display: flex;
          padding: 0.2rem;
          gap: 0.375rem;
          border-radius: var(--radius-4xl, 16rem);
          background: var(--text-base-light);
          transition: background 0.3s;
          color: var(--white);
          font-size: 0.875rem;
          font-weight: 800;
          width: 2rem;
          height: 2rem;
          align-items: center;
          justify-content: center;
        }
        &.active{
          .title{
            color: var(--text-brand);
          }
          .count{
            background: var(--text-brand);
          }
        }
        &:hover{
          .title{
            color: var(--text-brand);
          }
        }
      }
      +.results-link{
        border-top: 1px solid var(--surface-secondary);
        padding-top:var(--spacing-4);
      }
    }
    .results-link{
      display: flex;
      padding: var(--spacing-spacing-none, 0rem);
      align-items: center;
      gap: 0.625rem;
      color: var(--text-brand-dark);
      font-size: 1rem;
      font-weight: 500;
      cursor:pointer;
      transition: color 0.3s;
      &:hover{
        color: var(--surface-complementary);
      }
      i{
        font-size: 1.375rem;
      }
      +.results-link{
        margin-top:var(--spacing-2);
      }
    }
  }
}
@media (max-width: $tablet-breakpoint){
  page:has(#Section10000){
    #HeaderSlider{
      height: 300px;
    }
  }
  #Section10000{
    padding-top: 0;
  }
  .results-section{
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-top: var(--spacing-spacing-l);
    >.col-span-1{
      order:2;
    }
    .results-navigation{
      .results-menu{
        flex-direction: row;
        position: fixed;
        bottom:102px;
        left:0;
        background: var(--surface-brand);
        padding: var(--spacing-spacing-m);
        z-index: 9;
        gap: var(--spacing-spacing-m);
        width: 100%;
        overflow: auto;
        li{
          .title{
            font-size: 1rem;
            color: var(--white);
          }
          .count{
            display: none;
          }
          &.active{
            .title{
              color: var(--white);
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

