@import "breakpoints";
:root{
  --spacing-1: 0.5rem;
  --spacing-2: 1rem;
  --spacing-3: 1.5rem;
  --spacing-4: 2rem;
  --spacing-5: 2.5rem;
  --spacing-6: 3rem;
  --spacing-7: 3.5rem;
  --spacing-8: 4rem;
  --spacing-9: 4.5rem;
  --spacing-10: 5rem;
  --spacing-spacing-2xs: 0.125rem;
  --spacing-spacing-xs: 0.25rem;
  --spacing-spacing-s: 0.5rem;
  --spacing-spacing-m: 1rem;
  --spacing-spacing-l: 2rem;
  --spacing-spacing-xl: 4.5rem;
  --spacing-spacing-2xl: 6.5rem;
  --spacing-edges : 7.5rem;
  --spacing-mobile : 1.25rem;
}

.p-0{
  padding-left:0 !important;
  padding-right:0 !important;
  padding-top:0 !important;
  padding-bottom:0 !important;
}

.mt-3{
  margin-top:var(--spacing-3);
}

.mt-10{
  margin-top:var(--spacing-10);
}

@media (max-width: $mobile-breakpoint){
  :root{
    --spacing-edges : var(--spacing-mobile);
  }
}
