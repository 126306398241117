@import "breakpoints";
.tag{
  display: inline-flex;
  padding: var(--spacing-spacing-xs) var(--spacing-spacing-m);
  align-items: center;
  gap: 0.625rem;
  color: var(--white);
  background: var(--text-brand);
  justify-content: center;
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  transition: all 0.3s;
  &.tag-hoverable{
    cursor:pointer;
    &:hover{
      background: var(--surface-complementary);
      color: var(--white);
      &.tag-outline{
        border:1px solid var(--surface-complementary);
      }
    }

  }
  &.tag-outline{
    border:1px solid var(--text-brand);
    color: var(--text-brand);
    background: none;
  }
}
@media (max-width: $tablet-breakpoint){
  .tag{
    padding: 3px var(--spacing-spacing-m);
    font-size: 0.8rem;
    line-height: 1.5em;
  }
}
