body{
  dp-day-calendar {
    display: inline-block;

    .dp-day-calendar-container {
      background: var(--white);
      padding: 3px;
    }

    .dp-calendar-wrapper {
      box-sizing: border-box;
      border: none;

      .dp-calendar-weekday {
        &:first-child {
          border-left: none;
        }
      }
    }

    .dp-calendar-weekday {
      margin: 1px;
      border: none;
    }

    .dp-calendar-day {
      margin: 1px;
      border-radius: 50%;
      background: var(--surface-primary-ultra-light);
      border: none;
      transition: background 0.3s;
      color: var(--text-base) !important;
      &:hover {
        background: var(--surface-brand-light);
      }
    }

    .dp-selected {
      background: var(--surface-brand);
      color: #fff;
    }
  }

  dp-calendar-nav {
    .dp-calendar-nav-container {
      border: none;
    }
    .dp-nav-date-btn {
      border: none;
    }
    .dp-nav-btns-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      display: inline-block;
    }
    .dp-calendar-nav-right,.dp-calendar-nav-left{
      height:28px;
      width:28px;
      border-radius: 50%;
      border:none;
      margin-left:1px;
      transition: background-color 0.3s;
      color: var(--text-base) !important;
      &:hover{
        background: var(--surface-brand);
        color:var(--white) !important;
      }
    }
    .dp-calendar-secondary-nav-left {
      &:before {
        right: -10px;
      }
    }
    .dp-current-location-btn {
      display: none;
    }
    .dp-nav-header-btn {
      height: 20px;
      width: 80px;
      border: none;
      background: var(--surface-brand);
      color: var(--white);
      outline: none;
      transition: background 0.3s;
      &:hover {
        background: var(--surface-complementary);
      }
      &:active {
        background: rgba(0,0,0,.1);
      }
    }
  }

  dp-month-calendar{
    .dp-month-calendar-container{
      padding:2px;
    }
    .dp-calendar-month{
      padding: var(--spacing-spacing-2xs) var(--spacing-spacing-s);
      align-items: center;
      border-radius: var(--spacing-spacing-2xs);
      gap: 0.5rem;
      display: inline-flex;
      cursor: pointer;
      transition: all 0.3s;
      background: var(--surface-primary-ultra-light);
      color: var(--text-base) !important;
      border:0;
      height:30px;
      margin:1px;
      &:hover{
        background: var(--surface-brand);
        color: var(--white) !important;
      }
    }
  }
}
