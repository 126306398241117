@import "breakpoints";
$font : "avenir", Calibri, Arial, Helvetica;
$accentFont : "lemon milk", Arial, Helvetica;

@font-face {
  font-family: 'avenir';
  src: url('../assets/fonts/avenir_black-webfont.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'avenir';
  src: url('../assets/fonts/avenir_book-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'avenir';
  src: url('../assets/fonts/avenir_heavy-webfont.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

/*@font-face {
  font-family: 'avenir';
  src: url('../assets/fonts/avenir_light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}*/

@font-face {
  font-family: 'avenir';
  src: url('../assets/fonts/avenir_regular-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'lemon milk';
  src: url('../assets/fonts/lemonmilk-bold-webfont.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'lemon milk';
  src: url('../assets/fonts/lemonmilk-medium-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'lemon milk';
  src: url('../assets/fonts/lemonmilk-regular-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

:root{
  --font : "avenir";
  --accent-font : "lemon milk";
  font-family: var(--font);
  font-size: 15px;
}
@media (max-width: $tablet-breakpoint){
  :root{
    //font-size: 15px;
  }
}
@media (max-width: $mobile-breakpoint){
  :root{
   // font-size: 14px;
  }
}
