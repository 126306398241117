.gap-m{ gap:var(--spacing-spacing-m)}
.gap-l{ gap:var(--spacing-spacing-l)}
.gap-xl{ gap:var(--spacing-spacing-xl)}
.gap-2xl{ gap:var(--spacing-spacing-2xl)}

.row-gap-m{ row-gap:var(--spacing-spacing-m)}
.row-gap-l{ row-gap:var(--spacing-spacing-l)}
.row-gap-xl{ row-gap:var(--spacing-spacing-xl)}
.row-gap-2xl{ row-gap:var(--spacing-spacing-2xl)}

.col-gap-m{ column-gap:var(--spacing-spacing-m)}
.col-gap-l{ column-gap:var(--spacing-spacing-l)}
.col-gap-xl{ column-gap:var(--spacing-spacing-xl)}
.col-gap-2xl{ column-gap:var(--spacing-spacing-2xl)}
