.scroll-behavior-smooth{
  scroll-behavior: smooth;
}

.scrollbar::-webkit-scrollbar {
  width: 7px;
  height:7px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
  transition: background-color 0.3s;
  border-radius: 10rem;
  border: none;
}


.scrollbar::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.scrollbar-hoverable::-webkit-scrollbar-thumb {
  transition: background-color 0.3s;
  background-color: rgba(0,0,0,0.05);
}

.scrollbar-hoverable:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
}
