.searchbar-active{
  .custom-search-input {
    input {
      border: 1px solid var(--surface-brand) !important;
      background: var(--surface-primary) !important;
    }
    .lvi-icon-cross {
      right: var(--spacing-spacing-m);
      left: auto !important;
      font-size: 1.5rem !important;
      position:absolute;
    }
  }
}

#SearchSection.active .input-search-container:before{
  opacity: 1;
}

@media (max-width: $tablet-breakpoint){
  #SearchSection{
    app-custom-search-input {
      app-custom-input {
        input {
          padding-top: var(--spacing-spacing-m);
          padding-bottom: var(--spacing-spacing-m);
        }
      }
    }
    &.active {
      border-radius: 32px 32px 0 0;
      padding-top: var(--spacing-spacing-m);
      .input-search-container {
        &:before {
          opacity: 0;
        }
      }
      .featured-search{
        margin-top: var(--spacing-spacing-l);
      }

      app-custom-search-input {
        i {
          left: var(--spacing-2);

        }

        app-custom-input {
          input {
            padding: var(--spacing-spacing-s) var(--spacing-spacing-m);
            padding-left: var(--spacing-6);
            border-radius: 32px;
            width: 100%;
          }
        }
      }
    }
  }
}
