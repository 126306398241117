[class*='__placeholder']{
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgb(211,211,211);
  overflow: hidden;
  border-radius: 5px;
  opacity: 0.8;
  /*&:after{
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    content:"";
    background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    animation: loading 1s infinite;
  }*/
}

/*@keyframes loading {
  0%{
    left: -45%;
  }
  100%{
    left: 100%;
  }
}
*/
